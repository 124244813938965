<template>
  <div ref="modal" class="popup">
    <div class="popup__close" @click="closeCar">
      <svg
        width="62"
        height="62"
        padding="12px"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="padding: 12px"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.396 31.0909L58.9647 59.6202L59.7035 58.8822L31.1348 30.353L59.7035 1.82387L58.9647 1.08594L30.396 29.6152L1.82627 1.08594L1.0874 1.82384L29.6561 30.353L1.0874 58.8823L1.82627 59.6202L30.396 31.0909Z"
          fill="black"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    </div>

    <div class="popup__wrapper">
      <div class="popup__wrap">
        <div class="popup__title">
          Подать заявку на <span class="fw-bold">{{ car.name }}</span
          ><br />
          в
          <span class="accent fw-bold">{{ getActiveSite.address }}</span>
        </div>
        <div class="popup__content">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div
                class="popup__image"
                :class="!isFree ? 'popup__image_not-free' : ''"
              >
                <img :src="`${getFilePrefix}${car.image}`" :alt="car.name" />
              </div>
              <ul class="popup__list">
                <template v-for="(item, key) in carKeys">
                  <li v-if="car[key]" :key="key">
                    {{ item }}: <b>{{ car[key] }}</b>
                  </li>
                </template>
              </ul>
            </div>
            <div class="col-12 col-lg-4 pt-4 pt-lg-0" v-if="!verify">
              <span class="popup__pricing">Стоимость аренды:</span>
              <ul v-if="car.rates" class="popup__rates pb-4">
                <li
                  v-for="(rate, index) in car.rates"
                  :key="index"
                  class="d-flex justify-content-between"
                >
                  <span class="popup__rate-name">
                    <template v-if="rate.start_day && rate.end_day"
                      >{{ rate.start_day }} - {{ rate.end_day }} сут.</template
                    >
                    <template v-else>от {{ rate.start_day }} сут.</template>
                  </span>
                  <span class="popup__rate-price fw-bold"
                    >{{ rate.summ.toLocaleString() }} ₽/сут.</span
                  >
                </li>
              </ul>
              <side-bar-calendar
                :button="'Наличие на даты'"
                :clear-dates-prop="false"
              />
              <button
                class="button button_accent mt-3"
                :disabled="!getDate"
                @click="changeDate"
              >
                Проверить на даты
              </button>

              <template v-if="$route.query.from && $route.query.to">
                <div class="mt-3">
                  <div class="error" v-if="!isFree">
                    На указанные даты <br />({{
                      $moment($route.query.from).format("DD.MM.YYYY")
                    }}
                    - {{ $moment($route.query.to).format("DD.MM.YYYY") }})
                    <br />
                    а/м предварительно не свободен. Оставьте заявку и/или
                    свяжитесь с менеджером <br />
                    <a :href="`tel:${activeSite.phone_2}`">{{
                      activeSite.phone_2
                    }}</a>
                  </div>
                  <div class="success" v-else>
                    На указанные даты ({{
                      $moment($route.query.from).format("DD.MM.YYYY")
                    }}
                    - {{ $moment($route.query.to).format("DD.MM.YYYY") }}) доступность а/м уточняйте у менеджера
                  </div>
                </div>
              </template>

              <div
                class="car__form position-relative"
                v-if="$route.query.from && $route.query.to && !verify"
              >
                <div class="mb-3 d-flex justify-content-end">
                  <div class="car__clear" @click="clearData">
                    Отчистить данные
                  </div>
                </div>

                <div v-if="isAuth" class="mb-2">
                  <router-link to="client/order" class="button button_accent">
                    Отправить заявку в личный кабинет
                  </router-link>
                </div>

                <form @submit.prevent="validateData" class="form mt-4">
                  <v-text-field
                    v-model="form.name"
                    :readonly="isAuth"
                    label="Имя"
                    required
                    outlined
                  ></v-text-field>

                  <v-text-field
                    v-mask="'+7 (###) ###-##-##'"
                    v-model="form.phone"
                    :readonly="isAuth"
                    label="Телефон"
                    placeholder="Телефон +7 ("
                    required
                    outlined
                  ></v-text-field>

                  <v-text-field
                    v-model="form.email"
                    label="E-mail"
                    type="email"
                    outlined
                  ></v-text-field>

                  <v-textarea
                    v-model="form.comment"
                    label="Комментарий"
                    outlined
                    placeholder="Оставьте комментарий к заявке"
                    no-resize
                    rows="3"
                  ></v-textarea>

                  <div class="form__item pb-2">
                    <button
                      class="button"
                      type="submit"
                      :disabled="!form.phone.length || !form.name.length"
                    >
                      Отправить заявку
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div v-else class="col-12 col-lg-4 pt-4 pt-lg-0">
              <span class="popup__back" @click="verify = false">Назад</span>
              <span class="popup__pricing"
                >Проверьте и подтвердите отправку данных</span
              >
              <div class="popup__info pt-2">
                <b>Автомобиль:</b>
                <span>{{ car.name }}</span>
              </div>
              <div class="popup__info pt-2">
                <b>Срок аренды:</b>
                <span>
                  {{ $moment($route.query.from).format("DD.MM.YYYY") }} -
                  {{ $moment($route.query.to).format("DD.MM.YYYY") }}
                </span>
              </div>
              <div class="popup__info pt-2">
                <b>Тариф:</b>
                <span>
                  {{ tariffRate.name }} -
                  {{ tariffRate.summ.toLocaleString() }} р/сут.
                </span>
              </div>
              <div class="popup__info pt-2">
                <b>Имя:</b>
                <span>{{ form.name }}</span>
              </div>
              <div class="popup__info pt-2">
                <b>Телефон:</b>
                <span>{{ form.phone }}</span>
              </div>
              <div class="popup__info pt-2" v-if="form.email">
                <b>E-mail:</b>
                <span>{{ form.email }}</span>
              </div>
              <div class="popup__info pt-2" v-if="form.comment">
                <b>Комментарий:</b>
                <span>{{ form.comment }}</span>
              </div>
              <div class="popup__info pt-3">
                <button class="button" @click="sendForm">
                  Отправить заявку
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { find } from "lodash";
import SideBarCalendar from "@/components/layout/SideBar/SideBarCalendar";
import moment from "moment";

export default {
  name: "CarModal",
  components: { SideBarCalendar },
  data() {
    return {
      carKeys: {
        transmission: "Коробка передач",
        engine_power: "Мощность двигателя",
        fuel_consumption: "Расход топлива",
        air_conditioning: "Кондиционер",
        engine_volume: "Объем двигателя",
        heated_seats: "Подогрев сидений",
        drive_unit: "Привод",
        issue_year: "Год выпуска",
      },
      verify: false,
      form: {
        name: "",
        phone: "",
        email: null,
        comment: null,
      },
    };
  },
  computed: {
    ...mapGetters("user", ["user", "isAuth"]),
    ...mapGetters("car", ["getCars", "getFreeById"]),
    ...mapGetters("config", [
      "getActiveCity",
      "getSite",
      "getDate",
      "getActiveSite",
    ]),
    activeSite() {
      if (this.getActiveCity.sites) {
        return (
          find(this.getActiveCity.sites, (site) => site.id === this.getSite) ||
          {}
        );
      }
      return {};
    },
    getUserName() {
      return (this.user && this.user.client && this.user.client.name) || "";
    },
    getUserPhone() {
      return (this.user && this.user.client && this.user.client.phone) || "";
    },
    dateDiff() {
      if (!this.$route.query.from || !this.$route.query.to) return null;
      return (
        moment(this.$route.query.to).diff(
          moment(this.$route.query.from),
          "days"
        ) || 1
      );
    },
    car() {
      return (
        find(this.getCars, (car) => car.id === Number(this.$route.query.car)) ||
        {}
      );
    },

    tariffRate() {
      return (
        find(
          this.car.rates,
          (rate) =>
            rate.start_day <= this.dateDiff &&
            (rate.end_day >= this.dateDiff || rate.end_day === 0)
        ) || {}
      );
    },
    getFilePrefix() {
      return process.env.VUE_APP_FILE_PATH;
    },
    isFree: (vm) =>
      !vm.$route.query.from && !vm.$route.query.to
        ? true
        : !!vm.getFreeById(vm.car.id),
  },
  methods: {
    ...mapActions("car", ["loadFreeCars"]),
    validateData() {
      if (this.form.phone && this.form.name) {
        this.verify = true;
      }
    },
    clearData() {
      if (!this.isAuth) {
        this.form.name = "";
        this.form.phone = "";
      }
      this.form.comment = null;
      this.form.email = null;
    },
    async changeDate() {
      if (
        this.getDate.start !== this.$route.query.from ||
        this.getDate.end !== this.$route.query.to
      ) {
        await this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            from: this.getDate.start,
            to: this.getDate.end,
          },
        });
      }
      await this.loadFreeCars({
        from: this.$route.query.from,
        to: this.$route.query.to,
      });
    },

    clickToWrap(e) {
      if (e.target.classList.contains("popup")) {
        this.closeCar();
      }
    },
    closeCar() {
      return this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, car: undefined },
      });
    },
    async sendForm() {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute('6Ld6HTgqAAAAAHI7DydjlSpZhcLOKMtR3LagZd1w', {action: 'submit'})

        const payload = {
          'g-recaptcha-response': token,
          car_model: Number(this.$route.query.car),
          site_id: Number(this.getActiveSite.id),
          site_guid: this.getActiveSite.guid,
          from: this.$route.query.from,
          to: this.$route.query.to,
          rate: `${this.tariffRate.name} - ${this.tariffRate.summ} р/сут.`,
          ...this.form,
        };
        try {
          const response = await this.$axios.api.sendCar({ payload });
          await this.$router.replace({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              car: undefined,
              success: response.data.id,
            },
          });
        } catch (e) {
          console.log(e);
        }
      });


    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
    document.getElementById("popup-bg").classList.add("active");
    document.addEventListener("click", this.clickToWrap);

    this.form.name = this.getUserName;
    this.form.phone = this.getUserPhone;
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    document.getElementById("popup-bg").classList.remove("active");
    document.removeEventListener("click", this.clickToWrap);
  },
};
</script>

<style scoped lang="scss">
.popup {
  width: 100%;
  left: 0;
  //background-color: rgba(#000, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-modal;
  bottom: 0;
  &__wrapper {
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 81vw;
    min-width: 320px;
    overflow-y: auto;
    overflow-x: visible;
    @include media(768) {
      width: 100%;
      top: 0%;
      padding-top: 50px;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    left: 15vw;
    cursor: pointer;
    display: block;
    z-index: 30;
    background: gray;
    border-radius: 50%;
    @include media(768) {
      display: block;
      svg {
        height: auto;
        max-width: 30px;
      }
    }
  }
  &__wrap {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    overflow: visible;
    //overflow: auto;
  }
  &__back {
    cursor: pointer;
    color: #ff6600;
  }
  &__title {
    font-weight: 400;
    font-size: em(35);
    color: #000000;
    padding-bottom: 5vh;
    @include media(768) {
      font-size: em(32);
    }
  }
  &__image {
    img {
      max-width: 100%;
      vertical-align: top;
    }
    &_not-free {
      filter: grayscale(100%);
    }
  }
  &__pricing {
    font-weight: 500;
    font-size: em(31);
    color: #000000;
    padding-bottom: 20px;
    display: block;
  }
  &__rates {
    padding: 0;
    margin: 0;
    list-style: none;
    max-width: 350px;
    li {
      padding: 5px 0;
    }
    span {
      display: block;
      font-size: em(18);
    }
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    column-count: 2;
    font-size: em(20);
  }
  &__info {
    display: flex;
    justify-content: space-between;
    span {
      padding-left: 5px;
    }
  }
}
.error {
  color: red;
  font-size: em(14);
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  background: #f7f7f7;
}
.success {
  color: green;
  font-size: em(16);
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  background: #f7f7f7;
}
.car {
  &__clear {
    color: #ff6600;
    cursor: pointer;
    font-size: em(10);
    text-decoration: underline;
  }
}

.form {
  &__item {
    font-size: em(18);
  }
}
</style>
